export const urls = {
  applications: '/app-edge/application-catalog',
  addApplication: '/app-edge/application-catalog/add-application',
  editApplication: '/app-edge/application-catalog/edit-application',
  applicationGroups: '/app-edge/application-groups',
  addApplicationGroups: '/app-edge/application-groups/add',
  applicationSecurity: '/app-edge/application-security',
  attackAnalysis: '/app-edge/application-security/attack-analysis',
  policyManagement: '/app-edge/application-security/policy-management',
  bypassRules: '/app-edge/application-security/policy-management/bypass-rules',
  customRules: '/app-edge/application-security/policy-management/custom-rules',
  wafRules: '/app-edge/application-security/policy-management/waf-rules',
  addRule: '/app-edge/application-security/policy-management/add-rule',
  editRule: '/app-edge/application-security/policy-management/edit-rule',
  discovery: '/app-edge/discovery',
};
